<template>
  <div class="home">
    <div style="height:400px;background:#eee">
      <el-carousel :interval="5000" arrow="always" height="400px" trigger="click">
        <el-carousel-item v-for="item in 3" :key="item">
          <img src="~@/assets/bannar/banner@3x.png">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div class="title">
      <div class="c-box">
        <h3 class="tag-title">
          <span>最新报价</span>
        </h3>
      </div>
    </div> -->
    <!-- <div>
      <div class="c-box">
        <div v-for="(item,i) in forData" :key="i" class="goods-box" @click="goTo(item.id)">
          <img :src="item.picUrl">
          <div class="goods-content">
            <p class="money">{{ item.amountRange }}</p>
            <p class="content">{{ item.names }}</p>
          </div>
        </div> -->
    <!--         <div class="goods-box">
          <img src="~@/assets/goods/2@3x.png">
          <div class="goods-content">
            <p class="money">￥345</p>
            <p class="content">国际运输出口进口国际物流跨境电商（宁波·纽约）</p>
          </div>
        </div>
        <div class="goods-box">
          <img src="~@/assets/goods/1@3x.png">
          <div class="goods-content">
            <p class="money">￥345</p>
            <p class="content">国际运输出口进口国际物流跨境电商（宁波·纽约）</p>
          </div>
        </div>
        <div class="goods-box">
          <img src="~@/assets/goods/2@3x.png">
          <div class="goods-content">
            <p class="money">￥345</p>
            <p class="content">国际运输出口进口国际物流跨境电商（宁波·纽约）</p>
          </div>
        </div> -->
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
import { findOfferTop4 } from '@/api/order.js';
import { findMyAccount } from '@/api/user.js';
export default {
  name: 'Home',
  props: {

  },
  data: function() {
    return {
      forData: [],
      msg: 'Home',
      a: 0
    };
  },
  created() {
    this.findOfferTop4();
    this.findMyAccount();
  },
  mounted() {

  },
  methods: {
    findOfferTop4() {
      // console.log(this.$route.query.id)
      findOfferTop4({ goodId: this.$route.query.id }).then(result => {
        if (result.data.code == 0) {
          console.log(result.data);
          this.forData = result.data.data;
        }
      }).catch(error => {
        try {
          if (error.response) {
            this.$message.error({
              message: error.response.data.msg
            });
          } else {
            this.$message.error({
              message: '请求失败'
            });
          }
        } catch (err) {
          this.$message.error({
            message: err
          });
        }
      });
    },
    goTo(id) {
      this.$router.push({
        path: '/freightRate/goOrder',
        query: {
          id,
          oId: id
        }
      });
    },
    findMyAccount() {
      findMyAccount()
        .then(res => {
          if (+res.data.code === 0) {
            this.$store.dispatch('newName', res.data.data.company + ' -- ' + res.data.data.userName);
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.data.msg
          });
        });
    }
  }
};

</script>

<style scoped>
.title{height:80px;line-height:80px}
.goods-box{width:300px;float:left;background:#fff;margin:0px;padding:15px;box-sizing:border-box;cursor:pointer;}
.goods-content{}
.goods-box img{width:100%;height:190px;vertical-align: bottom;border: 1px solid #ccc;}
.goods-box>div{height:135px;padding:20px 24px;box-shadow: 0px 0px 1px 2px #eee;border: 1px solid #ccc;}
.money{color:#FC2222;font-size:16px;margin:0;}
.content{font-size:14px;margin-top:20px}
</style>
